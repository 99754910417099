import React, { forwardRef } from 'react'
import './styles.css';

function InlineLoader(
    props,
    ref
) {
    const { color, ...rest } = props;
    const heightAdjuster = <>&nbsp;</>

    return (
        <span className="loader_container" {...rest} ref={ref}>
            {heightAdjuster}
        </span>
    )
}

export default forwardRef(InlineLoader)
