import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import networkReducer from './networkReducer'
import gamedataReducer from './gamedataReducer'
import playerReducer from './playerReducer'
import contractsReducer from './contractsReducer'
import languageReducer from './languageReducer'

// Replace with a new one
// import statsReducer from './statsReducer';
import { statsReducer } from '../features/scores';

const reducer = combineReducers({
  routing: routerReducer,
  network: networkReducer,
  gamedata: gamedataReducer,
  player: playerReducer,
  contracts: contractsReducer,
  stats: statsReducer,
  lang: languageReducer,
});

export default reducer;
