import dotenv from 'dotenv';
import pkgWeb3 from 'web3';
// import * as pkgJSON from '../package.json' assert {type: "json"};
import * as pkgJSON from '../package.json';
dotenv.config()

export const DEBUG = process.env.NODE_ENV !== 'production'
export const DEBUG_REDUX = DEBUG
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

// Networks
export const NETWORKS = {
  UNDEFINED: undefined,
  LOCAL: {
    name: 'local',
    id: '*',
    url: 'http://localhost',
    port: 8545
  },
  ROPSTEN: {
    name: 'ropsten',
    id: '3',
    url: `${process.env.ROPSTEN_HOST}`,
    privKey: `${process.env.ROPSTEN_PRIV_KEY}`
  },
  RINKEBY: {
    name: 'rinkeby',
    id: '4',
    url: `${process.env.RINKEBY_HOST}`,
    privKey: `${process.env.RINKEBY_PRIV_KEY}`
  },
  GOERLI: {
    name: 'goerli',
    id: '5',
    url: `https://goerli.infura.io/v3/43a87caedae744fca8e98ac23aa2891c`,
    privKey: `${process.env.GOERLI_PRIV_KEY}`
  },
  MOONRIVER: {
    name: 'moonriver',
    id: '1285',
    url: 'https://rpc.moonriver.moonbeam.network',
    privKey: `${process.env.MOONRIVER_PRIV_KEY}`
  },
  SEPOLIA: {
    name: 'sepolia',
    id: '11155111',
    url: 'https://rpc.sepolia.org',
    privKey: `${process.env.SEPOLIA_PRIV_KEY}`
  },
  MUMBAI: {
    name: 'mumbai',
    id: '80001',
    url: `${process.env.MUMBAI_HOST}`,
    privKey: `${process.env.MUMBAI_PRIV_KEY}`
  },
  AMOY: {
    name: 'amoy',
    id: '80002',
    url: `${process.env.AMOY_HOST}`,
    privKey: `${process.env.AMOY_PRIV_KEY}`
  }
}

// Web3
export const Web3 = pkgWeb3;

// Misc
export const CLEAR_CONSOLE = !DEBUG
export const GOOGLE_ANALYTICS_ID = 'UA-85043059-4'

// Owner addresses
export const ADDRESSES = {
  [NETWORKS.LOCAL.name]: undefined,
  [NETWORKS.ROPSTEN.name]: '0x499Ab66749B8eBc370CC73458D33940ae13f5D99',
  [NETWORKS.RINKEBY.name]: '0x499Ab66749B8eBc370CC73458D33940ae13f5D99',
  [NETWORKS.GOERLI.name]: '0x17c8058C566704E2A2FC4b81a0Ba5003CCD3Ed9F',
  [NETWORKS.MOONRIVER.name]: '0x499Ab66749B8eBc370CC73458D33940ae13f5D99',
  [NETWORKS.MUMBAI.name]: '0x40C81F308115757e39cD2f06eb945c1596F23F30',
  [NETWORKS.AMOY.name]: '0x40C81F308115757e39cD2f06eb945c1596F23F30',
  [NETWORKS.SEPOLIA.name]: '0x499Ab66749B8eBc370CC73458D33940ae13f5D99'
}

// Storage
export const VERSION = pkgJSON.default.version
export const STORAGE_PLAYER_DATA_KEY = `ethernaut_player_data_${VERSION}_`

// Paths
export const PATH_ROOT = '/'
export const PATH_NOT_FOUND = '/404'
export const PATH_HELP = '/help'
export const PATH_LEVEL_ROOT = `${PATH_ROOT}level/`
export const PATH_LEVEL = `${PATH_LEVEL_ROOT}:address`
export const PATH_STATS = `${PATH_ROOT}stats`
export const PATH_SCOREBOARD = `${PATH_ROOT}scoreboard`

// RELEASE SENSITIVE
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
export const CUSTOM_LOGGING = true                              /* TRUE on production */
export const SHOW_ALL_COMPLETE_DESCRIPTIONS = false             /* FALSE on production */
export const SHOW_VERSION = true                                /* TRUE on production */

// export const ACTIVE_NETWORK = NETWORKS.RINKEBY
// export const ACTIVE_NETWORK = NETWORKS.GOERLI
// export const ACTIVE_NETWORK = NETWORKS.MOONRIVER
// export const ACTIVE_NETWORK = NETWORKS.LOCAL
// export const ACTIVE_NETWORK = NETWORKS.SEPOLIA
// export const ACTIVE_NETWORK = NETWORKS.MUMBAI
export const ACTIVE_NETWORK = NETWORKS.AMOY
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
