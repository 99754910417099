import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import fp from 'lodash/fp';
import Web3 from 'web3'

const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

const setScores = createAction('scores/setScores');
const setPlayersAndNicks = createAction('scores/setPlayersAndNicks');
const setPlayerScore = createAction('scores/setPlayerScore');
const setPlayerNick = createAction('scores/setPlayerNick');
const addPlayer = createAction('scores/addPlayer');

/**
 * @param {object}          props
 * @param {object[]}        props.levels - array of levels
 * @param {string}          props.playerAddress
 * @param {TruffleContract} props.ethernaut
 **/
const getPlayerScore = async ({ playerAddress, ethernaut, levels }) => {
    let inputData = []
    for(let i = 0; i < levels.length; i++) {
        inputData[i] = "0x83b03a8d000000000000000000000000" + playerAddress.slice(2) +
            web3.eth.abi.encodeParameter('uint256', levels[i].idx).slice(2);
    }

    // total score
    inputData[levels.length] = "0x6988f6c5000000000000000000000000" + playerAddress.slice(2);

    let result = await ethernaut.multicall(inputData);

    let levelResults = [];
    for(let i = 0; i < result.length - 1; i++) {
        levelResults[i] = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', result[i]));
    }

    let totalScore = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', result[result.length - 1]));

    // We cannot relay on levels to be sorted by index, because it's an object
    // And levelsResults in also not sorted
    // So we need to pair results with their levelIndex, sort them, and then take a sorted result
    const levelsScores = fp.pipe(
        // lodash fp.map in tricky and doesn't give index, unless you set cap: false
        fp.map.convert({ cap: false })((level, indexInResults) => [level.idx, levelResults[indexInResults]]),
        fp.sortBy(levelPair => levelPair[0]),
        fp.map(sortedLevel => sortedLevel[1])
    )(levels);

    return { levelsScores, totalScore, playerAddress };
};

/**
 * @param {string} playerAddress
 **/
export const updatePlayerScoreAction = (playerAddress) =>
    async (dispatch, getState) => {
        const { ethernaut } = getState().contracts;
        const { levels } = getState().gamedata;

        if (!ethernaut || !playerAddress)
            throw new Error('No ethernaut contract');

        const { levelsScores, totalScore } =
            await getPlayerScore({ playerAddress, ethernaut, levels });

        dispatch(setPlayerScore({ levelsScores, totalScore, playerAddress }))
    }

/**
 * @param {TruffleContract} ethernaut
 * **/
const getPlayersAndNicks = async (ethernaut) =>  {
    const players = await ethernaut.getUsers();

    let nicksRaw = [];
    let inputData = []

    for(let i = 0; i < players.length; i++) {
        inputData[i] = "0x54ff9643000000000000000000000000" + players[i].slice(2);
    }

    let result = await ethernaut.multicall(inputData);

    for(let i = 0; i < result.length; i++) {
        nicksRaw[i] = web3.eth.abi.decodeParameter('string', result[i]);
    }

    // Transform data to an object { address: nick }
    const nicks = fp.pipe(
        fp.map.convert({ cap: false })((player, index) => [player, nicksRaw[index]]),
        fp.fromPairs,
    )(players);

    return { players, nicks };
}

// Distinct action to handle players and nicks only
// Probably won't use anywhere either
export const getPlayersAndNicksAction = async (dispatch, getState) => {
    const { ethernaut } = getState().contracts;
    if (!ethernaut)
        throw new Error('No ethernaut contract');

    const { players, nicks } = getPlayersAndNicks(ethernaut);

    dispatch(setPlayersAndNicks({ players, nicks }));
}

// Distinct action to handle players and nicks only
// Probably won't use anywhere either
export const updatePlayerNickAction = (playerAddress) => async (dispatch, getState) => {
    const { ethernaut } = getState().contracts;
    if (!ethernaut)
        throw new Error('No ethernaut contract');

    // Just to keep two data instances up-to-date
    dispatch(addPlayer(playerAddress));

    const nick = await ethernaut.nicks(playerAddress);
    dispatch(setPlayerNick({ playerAddress, nick }));
}

// Big handler to do all the job: players, nicks, and all their scores
export const loadScoresAction = createAsyncThunk(
    'scores/loadScores',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { ethernaut } = getState().contracts;
        const { players } = getState().stats;

        if (!ethernaut)
            return rejectWithValue('No ethernaut contract');

        // If no players loaded, load them and use right away
        let _players = players;
        if (!players) {
            const { players, nicks } = await getPlayersAndNicks(ethernaut);
            _players = players;
            dispatch(setPlayersAndNicks({ players, nicks }));
        }

        const { levels } = getState().gamedata;

        let resultA = [];

        // declarations
        let inputDataA = [];
        let counter = 0;
        let outputData;
        let inputDataTotal = [];
        let outputDataTotal;
        let outputCounter = 0;
        let outputDataTotalCounter = 0;

        // steps
        let stepNum = 40;
        let remainder = _players.length % stepNum;
        let iterationNum = (_players.length - _players.length % stepNum) / stepNum;
        // console.log('stepNum: ', stepNum);
        // console.log('remainder: ', remainder);
        // console.log('iterationNum: ', iterationNum);

        for(let s = 0; s < iterationNum; s++) {
            // block 1
            // @todo create separate function
            inputDataA = [];
            counter = 0;
            for(let i = stepNum * s; i < stepNum * s + stepNum; i++) {
                for (let j = 0; j < levels.length; j++) {
                    inputDataA[counter] = "0x83b03a8d000000000000000000000000" + _players[i].slice(2) +
                        web3.eth.abi.encodeParameter('uint256', levels[j].idx).slice(2);

                    counter++;
                }
            }

            outputData = await ethernaut.multicall(inputDataA);
            // ----------

            // block 2
            // @todo create separate function
            inputDataTotal = [];
            counter = 0;
            for(let i = stepNum * s; i < stepNum * s + stepNum; i++) {
                inputDataTotal[counter] = "0x6988f6c5000000000000000000000000" + _players[i].slice(2);
                counter++;
            }

            outputDataTotal = await ethernaut.multicall(inputDataTotal);
            // -----------

            // block 3
            // @todo create separate function
            outputCounter = 0;
            outputDataTotalCounter = 0;
            for(let i = stepNum * s; i < stepNum * s + stepNum; i++) {
                let levelResults = [];

                for (let j = 0; j < levels.length; j++) {
                    levelResults[j] = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', outputData[outputCounter]));
                    outputCounter++;
                }

                let totalScore = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', outputDataTotal[outputDataTotalCounter]));
                outputDataTotalCounter++;

                let levelsScores = fp.pipe(
                    // lodash fp.map in tricky and doesn't give index, unless you set cap: false
                    fp.map.convert({cap: false})((level, indexInResults) => [level.idx, levelResults[indexInResults]]),
                    fp.sortBy(levelPair => levelPair[0]),
                    fp.map(sortedLevel => sortedLevel[1])
                )(levels);

                let playerAddress = _players[i];

                resultA[i] = { levelsScores, totalScore, playerAddress };
            }

            // -----------
        }

        // remainder
        // block 1
        // @todo create separate function
        inputDataA = [];
        counter = 0;
        for(let i = _players.length - remainder; i < _players.length; i++) {
            for (let j = 0; j < levels.length; j++) {
                inputDataA[counter] = "0x83b03a8d000000000000000000000000" + _players[i].slice(2) +
                    web3.eth.abi.encodeParameter('uint256', levels[j].idx).slice(2);

                counter++;
            }
        }
        outputData = await ethernaut.multicall(inputDataA);
        // --------

        // block 2
        // @todo create separate function
        inputDataTotal = [];
        counter = 0;
        for(let i = _players.length - remainder; i < _players.length; i++) {
            inputDataTotal[counter] = "0x6988f6c5000000000000000000000000" + _players[i].slice(2);
            counter++
        }

        outputDataTotal = await ethernaut.multicall(inputDataTotal);
        // --------

        // block 3
        // @todo create separate function
        outputCounter = 0;
        counter = 0;
        for(let i = _players.length - remainder; i < _players.length; i++) {
            let levelResults = [];

            for (let j = 0; j < levels.length; j++) {
                levelResults[j] = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', outputData[outputCounter]));
                outputCounter++;
            }

            let totalScore = web3.utils.toBN(web3.eth.abi.decodeParameter('uint256', outputDataTotal[counter]));
            counter++;

            let levelsScores = fp.pipe(
                // lodash fp.map in tricky and doesn't give index, unless you set cap: false
                fp.map.convert({cap: false})((level, indexInResults) => [level.idx, levelResults[indexInResults]]),
                fp.sortBy(levelPair => levelPair[0]),
                fp.map(sortedLevel => sortedLevel[1])
            )(levels);

            let playerAddress = _players[i];

            resultA[i] = { levelsScores, totalScore, playerAddress };
        }
        // --------

        return resultA;
    }
);

const scoresSlice = createSlice({
    name: 'scores',
    initialState: {
        players: null,
        scores: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setScores, (state, action) => {
                state.scores = action.payload;
            })
            .addCase(setPlayerScore, (state, action) => {
                state.loading = false;
                if (!state.scores)
                    state.scores = {};

                state.scores.push(action.payload);
            })
            .addCase(setPlayersAndNicks, (state, action) => {
                state.players = action.payload.players;
                state.nicks = action.payload.nicks
            })
            .addCase(setPlayerNick, (state, action) => {
                state.nicks[action.payload.playerAddress] = action.payload.nick;
            })
            .addCase(addPlayer, (state, action) => {
                const player = fp.find(action.payload, state.players);
                if (!player)
                    state.players.push(action.payload);
            })

            // createAsyncThunk gives us those useful dispatch options
            .addCase(loadScoresAction.fulfilled, (state, action) => {
                state.scores = action.payload;
                state.loading = false;
            })
            .addCase(loadScoresAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadScoresAction.rejected, (state, action) => {
                state.error = action.payload;
            })
    },
})

const { reducer } = scoresSlice;
export default reducer;
