import fp from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {loadScoresAction, updatePlayerNickAction, updatePlayerScoreAction} from "./slices.js";

import './scoreboard.css';
import InlineLoader from "../../components/InlineLoader";

/**
 * @param {object}  props
 * @param {BN[]}    props.levelsScores  - array of levels
 * @param {string}  props.playerNick
 * @param {BN}      props.totalScore
**/
const PlayerRow = ({ playerNick, playerIndex, levelsScores, totalScore }) => (
    <tr>
        <td>{playerIndex + 1}</td>
        <td>{playerNick}</td>
        {levelsScores?.map((score, index) => (
            <td key={index}>{score.toString()}</td>
        ))}
        <td>{totalScore.toString()}</td>
    </tr>
);

const TableLoader = React.memo(({ cols }) => (
    <>
        <tr>
            {[...Array(cols)].map((e, i) =>
                <td key={`loadrow-1${i}`}>
                    <InlineLoader />
                </td>
            )}
        </tr>
        <tr>
            {[...Array(cols)].map((e, i) =>
                <td key={`loadrow-2${i}`}>
                    <InlineLoader />
                </td>
            )}
        </tr>
    </>
));

export const Scoreboard = () => {
    const { scores, error, nicks, loading } = useSelector(state => state.stats);
    const { levels } = useSelector(state => state.gamedata);

    // console.log(scores);
    const sortedScores = useMemo(() => fp.pipe(
        fp.sortBy(['totalScore']),
        fp.orderBy(['totalScore'], ['desc'])
    )(scores), [scores]);

    const dispatch = useDispatch();

    const { ethernaut } = useSelector(state => state.contracts);
    useEffect(() => {
        if (ethernaut && !scores && !error) {
            dispatch(loadScoresAction());
        }
    }, [dispatch, error, ethernaut, scores]);

    const subscribeToUpdates = useCallback(() => {
        if (ethernaut) {
            const newScoreEvent = ethernaut.NewScore();
            newScoreEvent.on('data', (data) => {
                dispatch(loadScoresAction());
            });

            const newPlayerEvent = ethernaut.NewPlayerLog();
            newPlayerEvent.on('data', (data) => {
                const newPlayer = data.args.player;
                dispatch(updatePlayerNickAction(newPlayer));
                dispatch(updatePlayerScoreAction(newPlayer));
            });

            return () => {
                newScoreEvent.removeAllListeners();
                newPlayerEvent.removeAllListeners();
            };
        }
    }, [dispatch, ethernaut]);

    // Activate subscription
    useEffect(subscribeToUpdates, [subscribeToUpdates]);
    return (
        <div className="page-container">
            <table className="scoreboard">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Player</th>
                    {fp.values(levels).map((level) => (
                        <th key={"level-"+level.idx}>Level #{level.idx}</th>
                    ))}
                    <th>Total score</th>
                </tr>
                </thead>
                <tbody>
                {loading && (<TableLoader cols={fp.values(levels).length + 3 || 6} />)}
                {sortedScores?.map((item, idx) => (
                    <PlayerRow
                        key={`player-${item.playerAddress}-${idx}`}
                        playerNick={nicks[item.playerAddress]}
                        levelsScores={item.levelsScores}
                        playerIndex={idx}
                        totalScore={item.totalScore}
                    />
                ))}
                </tbody>
            </table>
        </div>
    )
};
